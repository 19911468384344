import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useParams, Link, useHistory } from 'react-router-dom';
import { notification, Spin, Button, Tabs } from 'antd';
import { SERVER_URL } from '../../config';
import SchoolListByCipcode from './SchoolListByCipcode';
import { SettingOutlined } from '@ant-design/icons';
import Admin from './tabs/DP_Admin';
import { ExportToExcel } from '../../components/csv';

const mapRankings = (list) => {
  list = list.map((item) => ({
    rank: item.rank,
    ...item,
    earningPlus: item.premiumAmount,
    earningPlusPerc: item.premiumPercentage,
    economicScore: item.valueRank,
  }));

  list.forEach((item) => {
    delete item.premiumAmount;
    delete item.premiumPercentage;
    delete item.valueRank;
  });

  return list;
};

const { TabPane } = Tabs;

const CipcodesView = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [title, setTitle] = useState(null);
  const [newTitle, setNewTitle] = useState(null);
  const [slug, setSlug] = useState(null);
  const [bachelors, setBachelors] = useState([]);
  const [masters, setMasters] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [bachelorsOnline, setBachelorsOnline] = useState([]);
  const [mastersOnline, setMastersOnline] = useState([]);
  const [assoicatesOnline, setAssociatesOnline] = useState([]);
  const [assoicates, setAssociates] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [working, setWorking] = useState(false);
  const [filter, setFilter] = useState(null);
  const [degree, setDegree] = useState(null);
  const [sort, setSort] = useState(null);
  const [online, setOnline] = useState(false);
  const [loanForgiveness, setLoanForgiveness] = useState(false);
  const [wpProfileLink, setWpProfileLink] = useState(null);
  const [featureImage, setFeatureImage] = useState(null);
  const [fullOnline, setFullOnline] = useState(false);
  const [onlineTab, setOnlineTab] = useState(false); // used to separate online from non-online filters
  const [tab, setTab] = useState('BACHELORS');
  const [shortcodes, setShortcodes] = useState({
    certificate: null,
    associate: null,
    bachelors: null,
    baccalaureate: null,
    masters: null,
    gradCertificate: null,
    doctoral: null,
    firstPro: null,
  });

  const { cipcode } = useParams();

  useEffect(() => {
    let urlQuery = '';
    if (filter) urlQuery += `&filter=${filter}`;
    if (degree) urlQuery += `&degree=${degree}`;
    if (sort) urlQuery += `&sort=${sort}`;
    if (online) urlQuery += `&online=${online}`;
    if (onlineTab) urlQuery += `&onlineTab=${onlineTab}`;
    if (fullOnline) urlQuery += `&fullOnline=${fullOnline}`;

    const fetchCipcode = async () => {
      try {
        setWorking(true);

        const res = await Axios.get(`${SERVER_URL}/dp-cipcodes/${cipcode}${urlQuery ? '?' + urlQuery : ''}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (res) {
          setTitle(res.data.title);
          setNewTitle(res.data.newTitle);
          setSlug(res.data.slug);
          setBachelors(res.data.bachelors);
          setMasters(res.data.masters);
          setCertificate(res.data.certificate);
          setBachelorsOnline(res.data.bachelorsOnline);
          setMastersOnline(res.data.mastersOnline);
          setAssociates(res.data.associates);
          setAssociatesOnline(res.data.associatesOnline);
          setLoanForgiveness(res.data.loanForgiveness);
          setWpProfileLink(res.data.wpLink);
          setFeatureImage(res.data.featureImage);
          if (res.data.shortcodes) setShortcodes(res.data.shortcodes);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error loading cipcodes',
          placement: 'bottomRight',
          duration: 3,
        });
      }
    };

    fetchCipcode();
  }, [fetchTrigger, filter, sort, fullOnline, online, onlineTab, currentuser.data.token]);

  // export to excel
  let filename = '';
  let excelData = [];
  if (!tab.includes('ONLINE')) {
    if (tab.includes('BACHELORS')) {
      filename = `cipcode_bachelors_${cipcode}`;
      excelData = mapRankings(bachelors);
    }
    if (tab.includes('MASTERS')) {
      filename = `cipcode_masters_${cipcode}`;
      excelData = mapRankings(masters);
    }
    if (tab.includes('CERTIFICATE')) {
      filename = `cipcode_certificate_${cipcode}`;
      excelData = mapRankings(certificate);
    }
  } else {
    if (tab.includes('BACHELORS')) {
      filename = `cipcode_bachelors_online_${cipcode}`;
      excelData = mapRankings(bachelorsOnline);
    }
    if (tab.includes('MASTERS')) {
      filename = `cipcode_masters_online_${cipcode}`;
      excelData = mapRankings(mastersOnline);
    }
  }

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          {!currentuser.data.role.includes('guest') && (
            <ExportToExcel
              csvData={excelData}
              fileName={filename}
              propsToRemove={['_id']}
              disabled={currentuser.data.role.includes('viewer')}
            />
          )}

          <h4>
            <strong>
              {cipcode} - {newTitle ? newTitle : title}
            </strong>
          </h4>

          <Link to={{ pathname: '/admin/degree-profiles/cipcodes', state: history.location.state }}>
            <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
              ALL CIPCODES
            </Button>
          </Link>
        </div>

        <Tabs
          defaultActiveKey={'BACHELORS'}
          onChange={(tab) => {
            setTab(tab);
            setOnlineTab(tab && tab.includes('ONLINE') ? true : false);
          }}
        >
          <TabPane tab='BACHELORS' key='BACHELORS'>
            <SchoolListByCipcode
              list={bachelors}
              degree='Bachelors Degree'
              setDegree={setDegree}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              online={online}
              setOnline={setOnline}
              isOnline={false}
              onlineTab={onlineTab}
              setOnlineTab={setOnlineTab}
              fullOnline={fullOnline}
              setFullOnline={setFullOnline}
              cipcode={cipcode}
            />
          </TabPane>

          <TabPane tab='MASTERS' key='MASTERS'>
            <SchoolListByCipcode
              list={masters}
              degree="Master's Degree"
              setDegree={setDegree}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              online={online}
              setOnline={setOnline}
              isOnline={false}
              onlineTab={onlineTab}
              setOnlineTab={setOnlineTab}
              fullOnline={fullOnline}
              setFullOnline={setFullOnline}
              cipcode={cipcode}
            />
          </TabPane>

          <TabPane tab='CERTIFICATE' key='CERTIFICATE'>
            <SchoolListByCipcode
              list={certificate}
              degree='Undergraduate Certificate or Diploma'
              setDegree={setDegree}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              online={online}
              setOnline={setOnline}
              isOnline={false}
              onlineTab={onlineTab}
              setOnlineTab={setOnlineTab}
              fullOnline={fullOnline}
              setFullOnline={setFullOnline}
              cipcode={cipcode}
            />
          </TabPane>

          <TabPane tab='ASSOCIATES' key='ASSOCIATES'>
            <SchoolListByCipcode
              list={assoicates}
              degree="Associate's Degree"
              setDegree={setDegree}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              online={online}
              setOnline={setOnline}
              isOnline={false}
              onlineTab={onlineTab}
              setOnlineTab={setOnlineTab}
              fullOnline={fullOnline}
              setFullOnline={setFullOnline}
              cipcode={cipcode}
            />
          </TabPane>

          <TabPane tab='BACHELORS ONLINE' key='BACHELORS ONLINE'>
            <SchoolListByCipcode
              list={bachelorsOnline}
              degree='Bachelors Degree'
              setDegree={setDegree}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              online={online}
              setOnline={setOnline}
              isOnline={true}
              onlineTab={onlineTab}
              setOnlineTab={setOnlineTab}
              fullOnline={fullOnline}
              setFullOnline={setFullOnline}
              cipcode={cipcode}
            />
          </TabPane>

          <TabPane tab='MASTERS ONLINE' key='MASTERS ONLINE'>
            <SchoolListByCipcode
              list={mastersOnline}
              degree="Master's Degree"
              setDegree={setDegree}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              online={online}
              setOnline={setOnline}
              isOnline={true}
              onlineTab={onlineTab}
              setOnlineTab={setOnlineTab}
              fullOnline={fullOnline}
              setFullOnline={setFullOnline}
              cipcode={cipcode}
            />
          </TabPane>

          <TabPane tab='ASSOCIATES ONLINE' key='ASSOCIATES ONLINE'>
            <SchoolListByCipcode
              list={assoicatesOnline}
              degree="Associate's Degree"
              setDegree={setDegree}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              online={online}
              setOnline={setOnline}
              isOnline={true}
              onlineTab={onlineTab}
              setOnlineTab={setOnlineTab}
              fullOnline={fullOnline}
              setFullOnline={setFullOnline}
              cipcode={cipcode}
            />
          </TabPane>

          {!currentuser.data.role.includes('guest') && (
            <TabPane
              tab={
                <span style={{ color: 'red' }}>
                  <SettingOutlined />
                  ADMIN
                </span>
              }
              key='ADMIN'
            >
              <Admin
                title={title}
                newTitle={newTitle}
                setNewTitle={setNewTitle}
                setFetchTrigger={setFetchTrigger}
                fetchTrigger={fetchTrigger}
                working={working}
                setWorking={setWorking}
                cipcode={cipcode}
                loanForgiveness={loanForgiveness}
                setLoanForgiveness={setLoanForgiveness}
                wpProfileLink={wpProfileLink}
                setWpProfileLink={setWpProfileLink}
                featureImage={featureImage}
                setFeatureImage={setFeatureImage}
                slug={slug}
                setSlug={setSlug}
                shortcodes={shortcodes}
                setShortcodes={setShortcodes}
              />
            </TabPane>
          )}
        </Tabs>
      </Spin>
    </div>
  );
};

export default CipcodesView;
