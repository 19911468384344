import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Websites from '../pages/websites/Websites';
import EditWebsite from '../pages/websites/EditWebsite';
import Careers from '../pages/careers/Careers';
import ViewCareer from '../pages/careers/ViewCareer';
import NewCareer from '../pages/careers/NewCareer';
import CipSocView from '../pages/careers/CipSocView';
import Payscale from '../pages/payscale/Payscale';
import ViewPayscale from '../pages/payscale/ViewPayscale';
import Bls from '../pages/bls/Bls';
import ViewBls from '../pages/bls/ViewBls';
import IPEDSTopLists from '../pages/ipedsTopLists/IPEDSTopLists';
import EditIPEDSTopList from '../pages/ipedsTopLists/EditIPEDSTopList';

import NewPLRanking from '../pages/ranking content/NewPLRanking';
import ViewPLRanking from '../pages/ranking content/ViewPLRanking';
import CustomTopLists from '../pages/ranking content/custom-top-lists/CustomTopLists';
import NewCustomTopList from '../pages/ranking content/custom-top-lists/NewCustomTopList';
import RankingsBySchool from '../pages/ranking content/RankingsBySchool';
import Graduate from '../pages/ranking content/Graduate';
import Undergraduate from '../pages/ranking content/Undergraduate';
import OnlineRankings from '../pages/ranking content/online/OnlineRankings';
import Associate from '../pages/ranking content/associate/Associate';
import EMISchoolsList from '../pages/ranking content/EMI/EMISchoolsList';

// import OnetRaw from '../pages/onet/Raw';
// import OnetSettings from '../pages/onet/Settings';
// import OnetCalculation from '../pages/onet/Calculation';
// import ViewOnet from '../pages/onet/RawView.';
// import ScoreView from '../pages/onet/ScoreView';

import Edit from '../pages/affiliateLinks/Edit';
import EditASD from '../pages/affiliateLinks/EditASD';
import CommercialSpace from '../pages/affiliateLinks/CommercialSpace';

import AffiliateSection from '../pages/affiliateLinks/AffiliateSection';
import CommercialProgramSection from '../pages/affiliateLinks/CommercialProgramSection';

import {
  UserOutlined,
  GlobalOutlined,
  ProfileOutlined,
  DatabaseOutlined,
  DollarOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import SchoolProfiles from '../pages/schoolProfiles/SchoolProfiles';
import SchoolProfile from '../pages/schoolProfiles/SchoolProfile';
import StateOrder from '../pages/affiliateLinks/StateOrder';
import Cipcodes from '../pages/degreeProfiles/Cipcodes';
import CipcodesView from '../pages/degreeProfiles/CipcodesView';
import SocCipView from '../pages/degreeProfiles/SocCipView';
import StateWAs from '../pages/degreeProfiles/StateWAs';
import Spotlight from '../pages/spotlight/Spotlight';
import RankingTags from '../pages/schoolProfiles/RankingTags';
import OtherRankings from '../pages/ranking content/other/OtherRankings';

import SingleRankingEditPage from '../pages/ranking content/SingleRankingEditPage';
import StateRankingsContent from '../pages/ranking content/StateRankingsContent';
import ViewStateRankingItems from '../pages/ranking content/state rankings/ViewStateRankingItems';
import SingleStateEditPage from '../pages/ranking content/state rankings/SingleStateEditPage';
import ViewInstitutionRankings from '../pages/ranking content/ViewInstitutionRankings';
import Crimes from '../pages/schoolProfiles/Crimes';
import MedicalAdmissions from '../pages/schoolProfiles/MedicalAdmissions';

const routes = [
  {
    label: 'Users',
    icon: UserOutlined,
    allowed: ['admin'],
    children: [
      {
        label: 'All Users',
        path: '/admin/users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New User',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New User Image',
        path: '/admin/new-user/:imgId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User Image',
        path: '/admin/edit-user/:imgId/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'View User',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Careers',
    icon: DollarOutlined,
    allowed: ['admin', 'editor'],
    children: [
      {
        label: 'All Careers',
        path: '/admin/careers',
        component: Careers,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'View Career',
        path: '/admin/view-career/:id',
        component: ViewCareer,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'New Career',
        path: '/admin/new-career',
        component: NewCareer,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Cip/Soc View',
        path: '/admin/career-cip-soc/:occCode',
        component: CipSocView,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'IPEDS Top Lists',
    icon: OrderedListOutlined,
    allowed: ['admin', 'editor'],
    children: [
      {
        label: 'All Top Lists',
        path: '/admin/ipeds-top-lists',
        component: IPEDSTopLists,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'New Top List',
        path: '/admin/new-ipeds-top-list',
        component: EditIPEDSTopList,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Edit Top List',
        path: '/admin/edit-ipeds-top-list/:id',
        component: EditIPEDSTopList,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Degree Profiles',
    icon: ProfileOutlined,
    allowed: ['admin', 'editor', 'guest'],
    children: [
      {
        label: 'Cipcodes',
        path: '/admin/degree-profiles/cipcodes',
        component: Cipcodes,
        allowed: ['admin', 'editor', 'guest'],
        showInMenu: true,
      },
      {
        label: 'View school ranks by cipcode',
        path: '/admin/degree-profiles/cipcodes/:cipcode',
        component: CipcodesView,
        allowed: ['admin', 'editor', 'guest'],
        showInMenu: false,
      },
      {
        label: 'State WAs',
        path: '/admin/degree-profiles/state-weighted-averages',
        component: StateWAs,
        allowed: ['admin', 'editor', 'guest'],
        showInMenu: true,
      },
      {
        label: 'Cip/Soc View 2',
        path: '/admin/cipcode-cip-soc/:cipcode',
        component: SocCipView,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'School Profiles',
    icon: ProfileOutlined,
    allowed: ['admin', 'editor', 'guest'],
    children: [
      {
        label: 'School Profiles',
        path: '/admin/school-profiles',
        component: SchoolProfiles,
        allowed: ['admin', 'editor', 'guest'],
        showInMenu: true,
      },
      {
        label: 'School Profile',
        path: '/admin/school-profiles/:id',
        component: SchoolProfile,
        allowed: ['admin', 'editor', 'guest'],
        showInMenu: false,
      },
      {
        label: 'Tags',
        path: '/admin/school-profiles-ranking-tags',
        component: RankingTags,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Crimes (full files)',
        path: '/admin/crimes-full',
        component: Crimes,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Medical schools addmission data',
        path: '/admin/medical-schools-admissions',
        component: MedicalAdmissions,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Ranking content',
    icon: OrderedListOutlined,
    allowed: ['admin', 'editor'],
    children: [
      {
        label: 'States',
        path: '/admin/rankings/states',
        component: StateRankingsContent,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'View state items',
        path: '/admin/rankings/view-state-items/:state',
        component: ViewStateRankingItems,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: "Associate's",
        path: '/admin/rankings/associate',
        component: Associate,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'New associate ranking',
        path: '/admin/rankings/new/associate',
        component: NewPLRanking,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'View associate ranking',
        path: '/admin/rankings/associate/:id',
        component: ViewPLRanking,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: "Bachelor's",
        path: '/admin/rankings/undergraduate',
        component: Undergraduate,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'New undergrad ranking',
        path: '/admin/rankings/new/undergraduate',
        component: NewPLRanking,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'View undergrad ranking',
        path: '/admin/rankings/undergraduate/:id',
        component: ViewPLRanking,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'View institution ranking',
        path: '/admin/rankings/institutional/:id',
        component: ViewInstitutionRankings,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'View EMI ranking',
        path: '/admin/rankings/emi',
        component: EMISchoolsList,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: "Master's",
        path: '/admin/rankings/graduate',
        component: Graduate,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'New grad ranking',
        path: '/admin/rankings/new/graduate',
        component: NewPLRanking,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'View grad ranking',
        path: '/admin/rankings/graduate/:id',
        component: ViewPLRanking,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Online',
        path: '/admin/rankings/online',
        component: OnlineRankings,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Other',
        path: '/admin/rankings/other',
        component: OtherRankings,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Other',
        path: '/admin/rankings/new/other',
        component: NewPLRanking,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'View grad ranking',
        path: '/admin/rankings/other/:id',
        component: ViewPLRanking,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Custom Top Lists',
        path: '/admin/rankings/custom-top-lists',
        component: CustomTopLists,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'New Custom Top List',
        path: '/admin/rankings/custom-top-lists/new',
        component: NewCustomTopList,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Edit Custom Top List',
        path: '/admin/rankings/custom-top-lists/edit/:id',
        component: NewCustomTopList,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Rankings by school',
        path: '/admin/school-rankings',
        component: RankingsBySchool,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Single Ranking content page',
        path: '/admin/rankings-content/:id',
        component: SingleRankingEditPage,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Single State Ranking content page',
        path: '/admin/rankings-state-content/:state',
        component: SingleStateEditPage,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Ranking Ads',
  //   icon: OrderedListOutlined,
  //   allowed: ['admin', 'editor'],
  //   children: [
  //     {
  //       label: 'All Ads',
  //       path: '/admin/rankings-ads',
  //       component: RankingsAds,
  //       allowed: ['admin', 'editor'],
  //       showInMenu: true,
  //     },
  //     {1
  //       label: 'Add New Ads',
  //       path: '/admin/rankings-ads-new',
  //       component: RankingsAdsNew,
  //       allowed: ['admin', 'editor'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Edit Ad',
  //       path: '/admin/rankings-ads-edit/:id',
  //       component: RankingsAdsEdit,
  //       allowed: ['admin', 'editor'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  {
    label: 'Commercial',
    icon: DollarOutlined,
    allowed: ['admin', 'editor'],
    children: [
      {
        label: 'Affiliate',
        path: '/admin/affiliate-links',
        component: AffiliateSection,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Administration',
        path: '/admin/affiliate-codes',
        component: CommercialProgramSection,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: '2U',
        path: '/admin/edit/provider/:provider/:id',
        component: Edit,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: '2U',
        path: '/admin/edit-asd/:id',
        component: EditASD,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Commercial space',
        path: '/admin/commercial-space',
        component: CommercialSpace,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'State Order',
        path: '/admin/state-order',
        component: StateOrder,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Spotlight',
        path: '/admin/spotlight',
        component: Spotlight,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Websites',
    icon: GlobalOutlined,
    allowed: ['admin', 'editor'],
    children: [
      {
        label: 'All Websites',
        path: '/admin/websites',
        component: Websites,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'New Website',
        path: '/admin/new-website',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: true,
      },
      {
        label: 'Edit Website',
        path: '/admin/edit-website/:id',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      /**
       * Website Top Lists
       */
      {
        label: 'Website Top Lists List',
        path: '/admin/edit-website/:id/list-top-list',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Website New Top List',
        path: '/admin/edit-website/:id/new-top-list',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Website Top List Edit',
        path: '/admin/edit-website/:id/edit-top-list/:topListId',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Website Top List View',
        path: '/admin/edit-website/:id/view-top-list/:topListId',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      /**
       * Website EAT Top Lists
       */
      {
        label: 'Website Top Lists List',
        path: '/admin/edit-website/:id/list-eat-top-list',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Website New Top List',
        path: '/admin/edit-website/:id/new-eat-top-list',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Website Top List Edit',
        path: '/admin/edit-website/:id/edit-eat-top-list/:eatTopListId',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Website Top List View',
        path: '/admin/edit-website/:id/view-eat-top-list/:eatTopListId',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      /**
       * Career widgets
       */
      {
        label: 'Career Widgets',
        path: '/admin/edit-website/:id/list-career-widgets',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Create/update Career Widgets',
        path: '/admin/edit-website/:id/new-career-widgets',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      /**
       * Degree widgets
       */
      {
        label: 'Degree Widgets',
        path: '/admin/edit-website/:id/list-degree-widgets',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
      {
        label: 'Create/update Degree Widgets',
        path: '/admin/edit-website/:id/new-degree-widgets',
        component: EditWebsite,
        allowed: ['admin', 'editor'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'External Data',
    icon: DatabaseOutlined,
    allowed: ['admin', 'editor', 'viewer'],
    children: [
      {
        label: 'Payscale',
        path: '/admin/payscale',
        component: Payscale,
        allowed: ['admin', 'editor', 'viewer'],
        showInMenu: true,
      },
      {
        label: 'View Payscale data',
        path: '/admin/view-payscale/:id',
        component: ViewPayscale,
        allowed: ['admin', 'editor', 'viewer'],
        showInMenu: false,
      },
      {
        label: 'BLS/LT Projections',
        path: '/admin/bls',
        component: Bls,
        allowed: ['admin', 'editor', 'viewer'],
        showInMenu: true,
      },
      {
        label: 'View BLS data',
        path: '/admin/view-bls/:id/:year',
        component: ViewBls,
        allowed: ['admin', 'editor', 'viewer'],
        showInMenu: false,
      },
      /**
       * ONET section
       */
      // {
      //   label: 'Onet',
      //   path: '/admin/onet/calculation',
      //   component: OnetCalculation,
      //   allowed: ['admin', 'editor'],
      //   showInMenu: true,
      // },
      // {
      //   label: 'Onet raw data',
      //   path: '/admin/onet/raw',
      //   component: OnetRaw,
      //   allowed: ['admin', 'editor'],
      //   showInMenu: false,
      // },
      // {
      //   label: 'Onet settings',
      //   path: '/admin/onet/settings',
      //   component: OnetSettings,
      //   allowed: ['admin', 'editor'],
      //   showInMenu: false,
      // },
      // {
      //   label: 'Onet calculation',
      //   path: '/admin/onet/calculation',
      //   component: OnetCalculation,
      //   allowed: ['admin', 'editor'],
      //   showInMenu: false,
      // },
      // {
      //   label: 'View Onet data',
      //   path: '/admin/onet/raw/:id',
      //   component: ViewOnet,
      //   allowed: ['admin', 'editor'],
      //   showInMenu: false,
      // },
      // {
      //   label: 'View Onet scores',
      //   path: '/admin/onet/score/:id',
      //   component: ScoreView,
      //   allowed: ['admin', 'editor'],
      //   showInMenu: false,
      // },
    ],
  },
];

export default routes;
